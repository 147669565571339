export const INITIAL_STATE_TOPICS = [
    {
        title: "HABITAÇÃO",
        objectives: [
            {
                title: "Conecta Comunidade",
                url: "https://drive.google.com/file/d/1nZv-jUWO9Vk2B0qBzxrwuK9rYifV5M1C/view?usp=sharing",
            },
        ],
        open: false,
    },
    {
        title: "MOBILIDADE URBANA",
        objectives: [
            { title: "Mobilidade Corporativa", url: "https://drive.google.com/file/d/1N82USgx6OUZBR0vGYZmC9iWgElpZvOw_/view?usp=sharing" },
            { title: "Dados em movimento", url: "https://drive.google.com/file/d/1G8jg5pLWWDPvhUBibu8n0p8iDIHdji9T/view?usp=sharing" },
        ],
        open: false,
    },
    {
        title: "SEGURANÇA ALIMENTAR",
        objectives: [
            {
                title: "Super Agricultores",
                url: "https://drive.google.com/file/d/1SSItcOJKvGsxW5jqICCnls54-aIJABef/view?usp=sharing",
            },
            {
                title: "Mercado+B",
                url: "https://drive.google.com/file/d/19gG5tyL5wbRwLxjmgRxiXsclBVKU-r1c/view?usp=sharing",
            },
        ],
        open: false,
    },
    {
        title: "TRABALHO E RENDA",
        objectives: [{ title: "Observatório do futuro do trabalho", url: "https://drive.google.com/file/d/1NwrEeRJ0ItreGv49NSRG6pPZDaMJ3RfM/view?usp=sharing" }],
        open: false,
    },
    {
        title: "ECONOMIA CIRCULAR",
        objectives: [
            {
                title: "ReShopping",
                url: "https://drive.google.com/file/d/1tkYsBuoYGeMENFtBroCwpUAQrYOHZQMs/view?usp=sharing",
            },
        ],
        open: false,
    },
];
