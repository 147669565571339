import { memo } from "react";
import styles from "./style.module.css";

import gerdauIcon from "../../assets/img/footer/Logomarca Gerdau O futuro se molda horizontal 1.png";
import movidaIcon from "../../assets/img/footer/MicrosoftTeams-image (5) 1.png";

import combioIcon from "../../assets/img/footer/COMBIO.png";

import heringIcon from "../../assets/img/footer/hering-logo-8.png";
import maeterraIcon from "../../assets/img/footer/mae terra 1.png";

import cidadespIcon from "../../assets/img/footer/Ativo 1 1.png";
import bwmIcon from "../../assets/img/footer/2022_BMWFoundation_allblack_transparent 1.png";
import wellbeingIcon from "../../assets/img/footer/image_6483441_Aplicação_Fundo Branco 1.png";
import redebrasilIcon from "../../assets/img/footer/UNGLOBAL.png";
import impacthubIcon from "../../assets/img/footer/hubsp 1.png";
import iceIcon from "../../assets/img/footer/logo_com_ass_horizontal (1) 1.png";

import cidadebIcon from "../../assets/img/footer/CIDADES+CIUDADES_BLACK.png";
import sistemabIcon from "../../assets/img/footer/logo_preto_sbb (1) 1.png";
import upIcon from "../../assets/img/footer/Logos UP-04 (2).png";
import causeIcon from "../../assets/img/footer/Group.png";
import laboraIcon from "../../assets/img/footer/laboralogo-backwhite-6402ec5fa2229e4cb3b28067e7d9db9b67b03c7da144f44bf627d64c7dea5cbb.png";
import solanoImg from "../../assets/img/footer/solano.png";
import g10Img from "../../assets/img/footer/g10.png";
import nespressoImg from "../../assets/img/footer/nespresso.png";
import loiImg from "../../assets/img/footer/loi.png";
import fecormecioImg from "../../assets/img/footer/fecomercio.png";
import fauuspImg from "../../assets/img/footer/fauusp.png";
import ecycleImg from "../../assets/img/footer/logo-ecycle.png";
import recodeImg from "../../assets/img/footer/logo-recode.png";
import fgvImg from "../../assets/img/footer/logo-fgv.png";
import exchangeImg from "../../assets/img/footer/logo-exchange.png";
import ellenImg from "../../assets/img/footer/logo-ellen.png";
import diagonalImg from "../../assets/img/footer/logo-diagonal.png";
import realixoImg from "../../assets/img/footer/logo-realixo.png";
import secovispImg from "../../assets/img/footer/logo-secovisp.png";
import negociosImg from "../../assets/img/footer/logo-negocios.png";
import centerNorteImg from "../../assets/img/footer/instituto-center-norte.png";
import kondzillaImg from "../../assets/img/footer/kondzilla.png";
import sescImg from "../../assets/img/footer/sesc.png";
import lentebImg from "../../assets/img/footer/lenteb.png";
import impactTradeImg from "../../assets/img/footer/impact-trade.jpg";

const Footer = () => {
    return (
        <footer id="footer" className={styles.footer}>
            <div className={styles.content}>
                <div className="container">
                    <h1 className={styles.title}>QUEM SOMOS</h1>
                    <div className={styles["content-wrapper"]}>
                        <strong>Patrocinador mantenedor</strong>
                        <div className={`${styles["d-flex"]} ${styles.patrocinadores}`}>
                            <div className={styles["content-logo"]}>
                                <img src={gerdauIcon} alt="Logomarca da Gerdau" />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]}>
                        <strong>Patrocinador ouro</strong>
                        <div className={`${styles["d-flex"]} ${styles.patrocinadores}`} style={{ width: "90%" }}>
                            <div className={styles["content-logo"]}>
                                <img src={movidaIcon} alt="Logomarca da Movida" />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]}>
                        <strong>Patrocinador prata</strong>
                        <div className={`${styles["d-flex"]} ${styles.patrocinadores}`} style={{ width: "90%" }}>
                            <div className={styles["content-logo"]}>
                                <img src={centerNorteImg} alt="Logomarca do instituto center norte" />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]}>
                        <strong>Patrocinadores bronze</strong>
                        <div className={`${styles["d-flex"]} ${styles.patrocinadores}`}>
                            <div className={styles["content-logo"]} data-margin-bottom="20">
                                <img src={combioIcon} alt="Logomarca da Combio" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={diagonalImg} alt="Logomarca da Diagonal" style={{ maxWidth: "204px" }} />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]}>
                        <strong>Apoiadores</strong>
                        <div className={`${styles["d-flex"]} ${styles.patrocinadores} ${styles.apoio}`}>
                            <div className={styles["content-logo"]}>
                                <img src={heringIcon} alt="Logomarca da Hering" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={maeterraIcon} alt="Logomarca da Mãe Terra" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={realixoImg} alt="Logomarca da Realixo" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={sescImg} alt="Logomarca do SESC" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={nespressoImg} alt="Logomarca da Nespresso" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={lentebImg} alt="Logomarca da LenteB" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={impactTradeImg} alt="Logomarca da Impact Trade" />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]} data-parceiro-inst>
                        <strong>Parceiros institucionais</strong>
                        <div className={styles["parceiros-institucionais"]}>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={cidadespIcon} alt="Logomarca da Cidade de São Paulo" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={20}>
                                <img src={bwmIcon} alt="Logomarca da BWM" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={20}>
                                <img src={wellbeingIcon} alt="Logomarca da Wellbeing" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={20}>
                                <img src={redebrasilIcon} alt="Logomarca da Rede Brasil" />
                            </div>
                            <div className={styles["content-logo"]} style={{ position: "relative", top: "-10px" }} data-element-anormale={true}>
                                <img src={impacthubIcon} alt="Logomarca da ImpactHub SP" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={iceIcon} alt="Logomarca da Inovação em Cidadania Empresarial" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={solanoImg} alt="Logomarca da Solano" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={g10Img} alt="Logomarca da G10+ Favelas" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={loiImg} alt="Logomarca da LOI" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={fecormecioImg} alt="Logomarca da Fecomercio" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={fauuspImg} alt="Logomarca da Fauusp" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={recodeImg} alt="Logomarca da recode" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={fgvImg} alt="Logomarca da FGV" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={exchangeImg} alt="Logomarca da Exchange" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom={40}>
                                <img src={ellenImg} alt="Logomarca da Ellen Macarthur" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={secovispImg} alt="Logomarca da SECOVISP" />
                                <img src={kondzillaImg} alt="Logomarca da KONDZILLA" />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]} data-parceria-midia>
                        <strong>Parceiros de Mídia</strong>
                        <div className={styles["parceiros-midias"]}>
                            <div className={styles["content-logo"]}>
                                <img src={negociosImg} alt="Logomarca da Negócios" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={ecycleImg} alt="Logomarca da ECycle" />
                            </div>
                        </div>
                    </div>
                    <div className={styles["content-wrapper"]}>
                        <strong>Correalizadores</strong>
                        <div className={`${styles["d-flex"]} ${styles.patrocinadores}`}>
                            <div className={styles["content-logo"]}>
                                <img src={cidadebIcon} alt="Logomarca da Cidade B" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={sistemabIcon} alt="Logomarca da BWM" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={upIcon} alt="Logomarca da UP" />
                            </div>
                            <div className={styles["content-logo"]} data-margin-bottom="20">
                                <img src={causeIcon} alt="Logomarca da Cause" />
                            </div>
                            <div className={styles["content-logo"]}>
                                <img src={laboraIcon} alt="Logomarca da Labora" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);
